"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var LZString = require("./lz-string");
var PROTOCOL_VERSION = "2.0";
var WatchRTCSocket = /** @class */ (function () {
    function WatchRTCSocket(options) {
        this.connection = null;
        this.wasConnected = false;
        this.buffer = [];
        this.sendInterval = 1;
        this.onClose = function () { };
        this.debug = false;
        this.dataCollection = true;
        this.sendPromises = [];
        this.trace = function (_a) {
            var _b;
            var data = _a.data, options = _a.options;
            var args = Array.prototype.slice.call(data);
            args.push(Date.now());
            if (args[1] instanceof RTCPeerConnection) {
                args[1] = args[1].__rtcStatsId;
            }
            if (!WatchRTCSocket._instance.dataCollection) {
                if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                    options.promiseFuncs.resolve({ error: "Data collection disabled" });
                }
                return;
            }
            if (!WatchRTCSocket._instance.connection) {
                if (WatchRTCSocket._instance.buffer.length > 1000) {
                    if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                        options.promiseFuncs.resolve({ error: "Message buffer size exceeded" });
                    }
                    return;
                }
                WatchRTCSocket._instance.buffer.push(args);
                if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                    WatchRTCSocket._instance.sendPromises.push(options.promiseFuncs);
                }
                return;
            }
            if (WatchRTCSocket._instance.connection.readyState === WebSocket.OPEN) {
                WatchRTCSocket._instance.buffer.push(args);
                if (options === null || options === void 0 ? void 0 : options.promiseFuncs) {
                    WatchRTCSocket._instance.sendPromises.push(options.promiseFuncs);
                }
                if (WatchRTCSocket._instance.buffer.length >= WatchRTCSocket._instance.sendInterval) {
                    var lines = JSON.stringify(WatchRTCSocket._instance.buffer);
                    var compressedMessage = LZString.compressToEncodedURIComponent(lines);
                    if (WatchRTCSocket._instance.debug) {
                        var logFunc = (_b = console._original_log) !== null && _b !== void 0 ? _b : console.log;
                        logFunc.apply(void 0, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["lines: ".concat(lines.length)], false));
                        logFunc.apply(void 0, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["compressedMessage: ".concat(compressedMessage.length)], false));
                    }
                    WatchRTCSocket._instance.buffer = [];
                    WatchRTCSocket._instance.connection.send(compressedMessage);
                    WatchRTCSocket._instance.sendPromises.forEach(function (_a) {
                        var resolve = _a.resolve;
                        return resolve({});
                    });
                    WatchRTCSocket._instance.sendPromises = [];
                }
            }
        };
        if (WatchRTCSocket._instance) {
            console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["WatchRTCSocket instance already created"], false));
        }
        else {
            WatchRTCSocket._instance = this;
            this.debug = !!options.debug;
        }
    }
    WatchRTCSocket.prototype.connect = function (_a) {
        var url = _a.url, onData = _a.onData, onError = _a.onError, onOpen = _a.onOpen, onClose = _a.onClose;
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
        }
        var _this = WatchRTCSocket._instance;
        WatchRTCSocket._instance.connection = new WebSocket(url, PROTOCOL_VERSION);
        WatchRTCSocket._instance.connection.onopen = onOpen || null;
        WatchRTCSocket._instance.connection.onclose = onClose || null;
        WatchRTCSocket._instance.connection.onmessage = function (e) {
            var _a;
            try {
                var data = JSON.parse(e.data);
                if (data.error) {
                    (_a = _this === null || _this === void 0 ? void 0 : _this.connection) === null || _a === void 0 ? void 0 : _a.close();
                    _this.connection = null;
                    console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["\n" + data.error], false));
                    onError(data.error, "auth");
                }
                else {
                    if (data.sendInterval) {
                        WatchRTCSocket._instance.sendInterval = data.sendInterval;
                    }
                    onData(data);
                    WatchRTCSocket._instance.wasConnected = true;
                }
            }
            catch (err) {
                console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), [{ err: err.stack }], false));
                onError(err.message);
            }
        };
        WatchRTCSocket._instance.connection.onerror = function (e) {
            console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), ["\n", e], false));
            onError(e, "connection");
        };
    };
    WatchRTCSocket.prototype.close = function (nailUpCallEnd) {
        var _a, _b;
        if (nailUpCallEnd && ((_b = (_a = WatchRTCSocket._instance) === null || _a === void 0 ? void 0 : _a.connection) === null || _b === void 0 ? void 0 : _b.readyState) === WebSocket.OPEN) {
            var lines = JSON.stringify(["nailUpCallEnd", null, null]);
            var compressedMessage = LZString.compressToEncodedURIComponent(lines);
            WatchRTCSocket._instance.connection.send(compressedMessage);
        }
        WatchRTCSocket._instance.buffer = [];
        WatchRTCSocket._instance.sendPromises.forEach(function (_a) {
            var resolve = _a.resolve;
            return resolve({ error: "Connection was close" });
        });
        WatchRTCSocket._instance.sendPromises = [];
        if (WatchRTCSocket._instance.connection) {
            WatchRTCSocket._instance.connection.close();
            WatchRTCSocket._instance.onClose();
            WatchRTCSocket._instance.connection = null;
        }
    };
    WatchRTCSocket.prototype.disableDataCollection = function () {
        if (WatchRTCSocket._instance.debug) {
            console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["Data collection disabled."], false));
        }
        WatchRTCSocket._instance.dataCollection = false;
    };
    WatchRTCSocket.prototype.enableDataCollection = function () {
        if (WatchRTCSocket._instance.debug) {
            console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["Data collection enabled."], false));
        }
        WatchRTCSocket._instance.dataCollection = true;
    };
    WatchRTCSocket.prototype.toggleDebug = function (debug) {
        WatchRTCSocket._instance.debug = debug;
    };
    return WatchRTCSocket;
}());
exports.default = WatchRTCSocket;
