"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./utils");
var WatchRTCHttp = /** @class */ (function () {
    function WatchRTCHttp(options) {
        this.debug = false;
        if (WatchRTCHttp._instance) {
            console.info.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("info"), false), ["WatchRTCSocket instance already created"], false));
        }
        else {
            WatchRTCHttp._instance = this;
            this.debug = !!options.debug;
        }
    }
    WatchRTCHttp.prototype.trace = function (url, projectId, rtcRoomId, rtcPeerId) {
        var data = [];
        for (var _i = 4; _i < arguments.length; _i++) {
            data[_i - 4] = arguments[_i];
        }
        var args = Array.prototype.slice.call(data);
        args.push(Date.now());
        if (args[1] instanceof RTCPeerConnection) {
            args[1] = args[1].__rtcStatsId;
        }
        var response = fetch(url, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: data,
                projectId: projectId,
                rtcRoomId: rtcRoomId,
                rtcPeerId: rtcPeerId,
            }),
        });
        return response
            .then(function () { return ({}); })
            .catch(function (err) {
            console.log.apply(console, __spreadArray(__spreadArray([], (0, utils_1.logPrefix)("error"), false), [err.message, { err: err.stack }], false));
            return { error: err };
        });
    };
    return WatchRTCHttp;
}());
exports.default = WatchRTCHttp;
